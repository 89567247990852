<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <b-card
      v-show="is_project_expense == null"
      title="Tipo de despesa (custo)"
      class="text-left"
    >
      <b-row
        class="my-1 m-2 pl-2 pr-2 text-center d-flex justify-content-center"
      >
        <b-col cols="12">
          <b-button
            class="m-2"
            variant="primary"
            @click="(is_project_expense = true), setType()"
            >Projeto</b-button
          >
          <b-button
            variant="warning"
            @click="(is_project_expense = false), setType()"
            >Escritório</b-button
          >
        </b-col>
      </b-row>
    </b-card>
    <b-card v-show="is_project_expense != null" title="" class="text-left">
      <b-form>
        <!-- <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="6" lg="6">
            <b-form-checkbox
              v-model="is_project_expense"
              name="check-button"
              @change="setType"
              switch
            >
              Despesa (custo) de projeto
            </b-form-checkbox>
          </b-col>
        </b-row> -->

        <!-- Projeto -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col
            v-show="is_project_expense"
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
          >
            <b-form-group>
              <label
                for="projectSelect"
                class="text-center font-weight-bold text-primary"
              >
                Projeto :
              </label>

              <div
                @click="handleShowArquived()"
                :style="{ color: 'blue', padding: '5px 0', cursor: 'pointer' }"
              >
                {{
                  showArquivded ? 'Ocultar arquivados' : 'Mostrar arquivados'
                }}
              </div>

              <b-form-select
                v-if="projects.length > 0"
                id="projectSelect"
                v-model="item.project_id"
                :options="projects"
                value-field="id"
                text-field="name"
                placeholder="Selecione um projeto"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="6" lg="6">
            <b-form-group class="mb-2">
              <label for="category" class="font-weight-bold text-primary">{{
                is_project_expense
                  ? 'Categoria do custo de PROJETO'
                  : 'Categoria do custo de ESCRITORIO'
              }}</label>
              <b-form-select
                id="category"
                placeholder="Selecione uma categoria"
                v-model="item.expense_category_id"
              >
                <option
                  v-for="categories in categories"
                  :key="categories.id"
                  :value="categories.id"
                >
                  {{ categories.expense_category_name }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col cols="12" xs="12" sm="12" md="6" lg="6">
            <label for="category" class="font-weight-bold text-primary"
              >Fornecedor :</label
            >
            <b-form-select id="category" v-model="item.supplier_id">
              <option
                v-for="supplier in supplier"
                :key="supplier.id"
                :value="supplier.id"
              >
                {{ supplier.supplier_name }}
              </option>
            </b-form-select>
          </b-col>
        </b-row>

        <!-- Valor -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="value"
                class="text-center font-weight-bold text-primary"
                >Valor :</label
              >

              <b-input-group>
                <BFormInput
                  id="value"
                  type="text"
                  v-model="item.value"
                  v-money="moneyMask"
                ></BFormInput>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Data Refrencia -->

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="date" class="font-weight-bold text-primary"
                >Data de Referência :</label
              >
              <BFormInput
                id="date"
                type="date"
                v-model="item.reference_date"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Data Transação -->

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="date" class="font-weight-bold text-primary"
                >Data de Transação :</label
              >
              <BFormInput
                id="date"
                type="date"
                v-model="item.transaction_date"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Status -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="status" class="font-weight-bold text-primary"
                >Status :</label
              >
              <b-form-select
                id="status"
                v-model="item.status"
                :options="statusOptions"
                placeholder="Selecione o status"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Descrição -->

        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="description" class="font-weight-bold text-primary"
                >Descrição :</label
              >
              <BFormTextarea
                id="description"
                type="textarea"
                v-model="item.description"
              ></BFormTextarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <!-- Tipo -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label class="font-weight-bold text-primary"
              >Condição de Pagamento:</label
            >
            <b-form-select
              v-model="item.type"
              :options="typesOptions"
              placeholder="Selecione o status"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Se Parcelado -->
      <div
        v-if="
          item.type == 'Parcelado' || (item.type == 'Recorrência' && item.value)
        "
      >
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <b-input-group
                :prepend="
                  item.type == 'Parcelado' ? 'Parcelas' : 'Recorrências'
                "
              >
                <BFormInput
                  id="installmentsInput"
                  type="number"
                  v-model="installmentsInput"
                ></BFormInput>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Parcelas -->
        <div v-if="item.value && installmentsInput > 0">
          <div v-for="i in parseInt(installmentsInput)" :key="i">
            <b-row
              class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
            >
              <b-col col xs="12" sm="12" md="6" lg="6">
                <b-form-group>
                  <b-input-group prepend="R$">
                    <BFormInput
                      :id="`installment-value`"
                      type="text"
                      :ref="`installments-value-${i}`"
                      :value="
                        numberToMonetary(
                          monetaryToNumber(item.value) /
                            parseInt(installmentsInput)
                        )
                      "
                      v-money="moneyMask"
                    ></BFormInput>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col col xs="12" sm="12" md="6" lg="6">
                <b-form-group prepend="">
                  <BFormInput
                    :id="`installment-date`"
                    :ref="`installments-date-${i}`"
                    type="date"
                    :value="installmentIncrease(i)"
                  ></BFormInput>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <!-- Se Repetir -->

      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col cols="12" xs="12" sm="12" md="6" lg="6">
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              Inserindo custo de
              {{ is_project_expense ? 'PROJETO' : 'ESCRITÓRIO' }}
            </p>
            <hr />
          </b-alert>
        </b-col>
      </b-row>

      <b-row
        v-if="item.type == 'Recorrência' || item.type == 'Parcelado'"
        class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
      >
        <b-col cols="12" xs="12" sm="12" md="6" lg="6">
          <b-alert show variant="success" class="p-1">
            <p class="text-center">
              Transações
              {{
                item.type == 'Recorrência'
                  ? 'de RECORRÊNCIA são despesas ordinárias para manutenção do escritório. (Aluguel, energia, água, internet)'
                  : 'de PARCELAMENTO são aquelas operações que necessitam de prazo para serem realizadas.'
              }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <!-- Botoes -->
      <div class="justify-center">
        <BButton
          variant="outline"
          @click="$router.push('/lancamento-de-despesa')"
          >Voltar</BButton
        >
        <BButton @click="saveItem">Salvar</BButton>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormSelect,
  BInputGroup,
  //BFormCheckbox,
  BAlert,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';
import moment from 'moment';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
    BInputGroup,
    //BFormCheckbox,
    BAlert,
  },
  data: () => ({
    is_project_expense: null,
    supplier_id: '',
    categories: [],
    item: {
      project_id: null,
      type: 'a vista',
      status: 'previsto',
      value: 0,
      expense_category_id: null,
      repeat: 0,
      transaction_date: moment().format('YYYY-MM-DD'),
      reference_date: moment().format('YYYY-MM-DD'),
    },
    moneyMask: {
      prefix: '',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
    supplier: [],
    installments: [],
    projects: [],
    installmentsInput: 2,
    statusOptions: ['previsto', 'realizado'],
    typesOptions: ['À vista (uma vez)', 'Parcelado', 'Recorrência'],
    showArquivded: false,
    instalment_date_increase: moment().format('YYYY-MM-DD'),
  }),

  created() {
    this.getProjects();
    this.getCategories();
    this.getSupplier() 
  },

  computed: {},

  methods: {
    setType() {
      this.typesOptions = [];
      if (this.is_project_expense == true) {
        this.typesOptions.push('À vista (uma vez)', 'Parcelado');
      } else {
        this.typesOptions.push('À vista (uma vez)', 'Parcelado', 'Recorrência');
      }
      this.getCategories();
    },
    async getCategories() {
      await this.$store
        .dispatch('getAllCostsCategory', {
          inactive: false,
          workspace_id: this.$store.getters.currentWorkspace.id,
          cost_category: this.is_project_expense ? 'project' : null,
        })
        .then((resp) => {
          if (resp) {
            this.categories = resp.data;
          }
        });
    },
    async getSupplier() {
      await this.$store
        .dispatch('getAllSuppliers', {
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            this.supplier = resp;
          }
        });
    },
    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          resp.data.map((e) => {
            if (this.showArquivded) {
              this.projects.push({
                id: e.id,
                name: `${e.project_code} - ${e.project_name} ${
                  e.is_archived ? '(Arquivado)' : ''
                }`,
              });
            } else {
              if (e.is_archived) {
                return null;
              } else {
                this.projects.push({
                  id: e.id,
                  name: `${e.project_code} - ${e.project_name} ${
                    e.is_archived ? '(Arquivado)' : ''
                  }`,
                });
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async saveItem() {
      if (
        this.item.type === 'Parcelado' ||
        (this.item.type === 'Recorrência' && this.installmentsInput > 0)
      ) {
        for (let i = 1; i <= parseInt(this.installmentsInput); i++) {
          const valueInstallments = this.monetaryOnlyCommas(
            this.$refs[`installments-value-${i}`][0].value
          );
          console.log(
            'PARCELA: ',
            this.$refs[`installments-value-${i}`][0].value
          );
          console.log('PARCELA convertida: ', valueInstallments);

          const dateInstallments =
            this.$refs[`installments-date-${i}`][0].$refs.input.value;

          this.installments.push({
            value: valueInstallments,
            transaction_date: dateInstallments,
          });
        }
      }

      this.$store
        .dispatch('saveExpense', {
          ...this.item,
          description: this.item.description
            ? this.item.description
            : 'Sem descrição',
          value: this.monetaryToNumber(this.item.value),
          installments: this.installments,
          repeat: this.item.type === 'Recorrência' ? 1 : 0,
          workspace_id: this.$store.getters.currentWorkspace.id,
          is_project_expense: this.is_project_expense,
        })
        .then((resp) => {
          console.log(resp);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Despesa registrada com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.$router.push('/lancamento-de-despesa');
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao lançar despesa.',
              text: error,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    installmentIncrease(i) {
      let installmentDate = moment();
      installmentDate.add(i, 'months');

      return installmentDate.format('YYYY-MM-DD');
    },

    async createInstallmentsLength() {
      if (this.installments.length < this.installmentsInput) {
        for (
          let i = this.installments.length;
          i < this.installmentsInput;
          i++
        ) {
          this.installments.push({ value: 0 });
        }
      } else if (
        this.installments.length > this.installmentsInput &&
        this.installmentsInput !== 0
      ) {
        for (
          let i = this.installments.length - 1;
          i >= this.installmentsInput;
          i--
        ) {
          console.log('minus');
          this.installments.pop();
        }
      }

      console.log(this.installments);
    },

    handleShowArquived() {
      this.showArquivded = !this.showArquivded;
      this.projects = [];
      this.getProjects();
    },
  },
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
</style>
